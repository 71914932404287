var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"custom-search d-flex justify-content-end"}),_c('app-collapse',[_c('app-collapse-item',{attrs:{"title":"Données générales"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Agréement","label-for":"Agréement"}},[_c('validation-provider',{attrs:{"name":"Agréement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Agréement"},model:{value:(_vm.university.accreditation),callback:function ($$v) {_vm.$set(_vm.university, "accreditation", $$v)},expression:"university.accreditation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Préfixe","label-for":"Préfixe"}},[_c('validation-provider',{attrs:{"name":"Préfixe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Préfixe"},model:{value:(_vm.university.prefix),callback:function ($$v) {_vm.$set(_vm.university, "prefix", $$v)},expression:"university.prefix"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Nom du faculté","label-for":"Nom du faculté"}},[_c('validation-provider',{attrs:{"name":"Nom du faculté","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Nom du faculté"},model:{value:(_vm.university.university_name),callback:function ($$v) {_vm.$set(_vm.university, "university_name", $$v)},expression:"university.university_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Acronyme","label-for":"Acronyme"}},[_c('validation-provider',{attrs:{"name":"Acronyme","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Acronyme"},model:{value:(_vm.university.acronym),callback:function ($$v) {_vm.$set(_vm.university, "acronym", $$v)},expression:"university.acronym"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Nom de la société","label-for":"Nom de la société"}},[_c('validation-provider',{attrs:{"name":"Nom de la société","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Nom de la société"},model:{value:(_vm.university.company_name),callback:function ($$v) {_vm.$set(_vm.university, "company_name", $$v)},expression:"university.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Matricule fiscal","label-for":"Matricule fiscal"}},[_c('validation-provider',{attrs:{"name":"Matricule fiscal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Matricule fiscal"},model:{value:(_vm.university.tax_identification_number),callback:function ($$v) {_vm.$set(_vm.university, "tax_identification_number", $$v)},expression:"university.tax_identification_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Régistre du commerce","label-for":"Régistre du commerce"}},[_c('validation-provider',{attrs:{"name":"Régistre du commerce","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Régistre du commerce"},model:{value:(_vm.university.commercial_register),callback:function ($$v) {_vm.$set(_vm.university, "commercial_register", $$v)},expression:"university.commercial_register"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Cnss","label-for":"Cnss"}},[_c('validation-provider',{attrs:{"name":"Cnss","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Cnss"},model:{value:(_vm.university.cnss),callback:function ($$v) {_vm.$set(_vm.university, "cnss", $$v)},expression:"university.cnss"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Rib","label-for":"Rib"}},[_c('validation-provider',{attrs:{"name":"Rib","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Rib"},model:{value:(_vm.university.rib),callback:function ($$v) {_vm.$set(_vm.university, "rib", $$v)},expression:"university.rib"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Iban","label-for":"Iban"}},[_c('validation-provider',{attrs:{"name":"Iban","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Iban"},model:{value:(_vm.university.iban),callback:function ($$v) {_vm.$set(_vm.university, "iban", $$v)},expression:"university.iban"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Code SWIFT","label-for":"Code SWIFT"}},[_c('validation-provider',{attrs:{"name":"Code SWIFT","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Code SWIFT"},model:{value:(_vm.university.swift_code),callback:function ($$v) {_vm.$set(_vm.university, "swift_code", $$v)},expression:"university.swift_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"% TVA","label-for":"% TVA"}},[_c('validation-provider',{attrs:{"name":"% TVA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"% TVA","type":"number"},model:{value:(_vm.university.tva),callback:function ($$v) {_vm.$set(_vm.university, "tva", $$v)},expression:"university.tva"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('label',{attrs:{"for":"example-datepicker"}},[_vm._v("Date Agréement")]),_c('flat-pickr',{staticClass:"form-control invoice-edit-input",model:{value:(_vm.university.accreditation_date),callback:function ($$v) {_vm.$set(_vm.university, "accreditation_date", $$v)},expression:"university.accreditation_date"}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"% RS Enseignant","label-for":"% RS Enseignant"}},[_c('validation-provider',{attrs:{"name":"% RS Enseignant","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"% RS Enseignant","type":"number"},model:{value:(_vm.university.rs_teacher),callback:function ($$v) {_vm.$set(_vm.university, "rs_teacher", $$v)},expression:"university.rs_teacher"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"% RS Fournisseur","label-for":"% RS Fournisseur"}},[_c('validation-provider',{attrs:{"name":"% RS Fournisseur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"% RS Fournisseur","type":"number"},model:{value:(_vm.university.rs_supplier),callback:function ($$v) {_vm.$set(_vm.university, "rs_supplier", $$v)},expression:"university.rs_supplier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"% RS Fonction libérale","label-for":"% RS Fonction libérale"}},[_c('validation-provider',{attrs:{"name":"% RS Fonction libérale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"% RS Fonction libérale","type":"number"},model:{value:(_vm.university.rs_liberal_function),callback:function ($$v) {_vm.$set(_vm.university, "rs_liberal_function", $$v)},expression:"university.rs_liberal_function"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('app-collapse-item',{attrs:{"title":"Cadres généraux"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nom & prénom du PDG","label-for":"Nom & prénom du PDG"}},[_c('validation-provider',{attrs:{"name":"Nom & prénom du PDG","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Nom & prénom du PDG"},model:{value:(_vm.university.fullname_ceo),callback:function ($$v) {_vm.$set(_vm.university, "fullname_ceo", $$v)},expression:"university.fullname_ceo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nom & Prénom Financier","label-for":"Nom & Prénom Financier"}},[_c('validation-provider',{attrs:{"name":"Nom & Prénom Financier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Nom & Prénom Financier"},model:{value:(_vm.university.fullname_financial),callback:function ($$v) {_vm.$set(_vm.university, "fullname_financial", $$v)},expression:"university.fullname_financial"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Gender","label-for":"gender"}},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"placeholder":"Mademoiselle","label":"name","options":_vm.gender}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Secrétaire général(e)","label-for":"Secrétaire général(e)"}},[_c('validation-provider',{attrs:{"name":"Secrétaire général(e)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Secrétaire général(e)"},model:{value:(_vm.university.general_secretary_name),callback:function ($$v) {_vm.$set(_vm.university, "general_secretary_name", $$v)},expression:"university.general_secretary_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Gender","label-for":"gender"}},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"placeholder":"Mademoiselle","label":"name","options":_vm.gender}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Directeur","label-for":"Directeur"}},[_c('validation-provider',{attrs:{"name":"Directeur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Directeur"},model:{value:(_vm.university.director_name),callback:function ($$v) {_vm.$set(_vm.university, "director_name", $$v)},expression:"university.director_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"CIN Directeur","label-for":"CIN Directeur"}},[_c('validation-provider',{attrs:{"name":"CIN Directeur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"CIN Directeur"},model:{value:(_vm.university.cin_director),callback:function ($$v) {_vm.$set(_vm.university, "cin_director", $$v)},expression:"university.cin_director"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('app-collapse-item',{attrs:{"title":"Coordonnées"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Adresse","label-for":"Adresse"}},[_c('validation-provider',{attrs:{"name":"Adresse","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Adresse"},model:{value:(_vm.university.address),callback:function ($$v) {_vm.$set(_vm.university, "address", $$v)},expression:"university.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Ville","label-for":"Ville"}},[_c('validation-provider',{attrs:{"name":"Ville","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Ville"},model:{value:(_vm.university.town),callback:function ($$v) {_vm.$set(_vm.university, "town", $$v)},expression:"university.town"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Code postal","label-for":"Code postal"}},[_c('validation-provider',{attrs:{"name":"Code postal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Code postal"},model:{value:(_vm.university.postal_code),callback:function ($$v) {_vm.$set(_vm.university, "postal_code", $$v)},expression:"university.postal_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Page Facebook","label-for":"Page Facebook"}},[_c('validation-provider',{attrs:{"name":"Page Facebook","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Page Facebook"},model:{value:(_vm.university.facebook),callback:function ($$v) {_vm.$set(_vm.university, "facebook", $$v)},expression:"university.facebook"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Page LinkedIn","label-for":"Page LinkedIn"}},[_c('validation-provider',{attrs:{"name":"Page LinkedIn","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Page LinkedIn"},model:{value:(_vm.university.linkedin),callback:function ($$v) {_vm.$set(_vm.university, "linkedin", $$v)},expression:"university.linkedin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.university.email),callback:function ($$v) {_vm.$set(_vm.university, "email", $$v)},expression:"university.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Tel","label-for":"Tel"}},[_c('validation-provider',{attrs:{"name":"Tel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Tel","type":"number"},model:{value:(_vm.university.phone),callback:function ($$v) {_vm.$set(_vm.university, "phone", $$v)},expression:"university.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Fax","label-for":"Fax"}},[_c('validation-provider',{attrs:{"name":"Fax","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Fax"},model:{value:(_vm.university.fax),callback:function ($$v) {_vm.$set(_vm.university, "fax", $$v)},expression:"university.fax"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Site Internet","label-for":"Site Internet"}},[_c('validation-provider',{attrs:{"name":"Site Internet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Site Internet"},model:{value:(_vm.university.website),callback:function ($$v) {_vm.$set(_vm.university, "website", $$v)},expression:"university.website"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('app-collapse-item',{attrs:{"title":"Logo et Signatures"}},[_vm._v(" Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake. ")]),_c('app-collapse-item',{attrs:{"title":"المعطيات بالعربية"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"اسم الجامعة","label-for":"اسم الجامعة"}},[_c('validation-provider',{attrs:{"name":"اسم الجامعة","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"اسم الجامعة"},model:{value:(_vm.university.faculty_name_arabe),callback:function ($$v) {_vm.$set(_vm.university, "faculty_name_arabe", $$v)},expression:"university.faculty_name_arabe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"اسم ولقب المدير","label-for":"اسم ولقب المدير"}},[_c('validation-provider',{attrs:{"name":"اسم ولقب المدير","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"اسم ولقب المدير"},model:{value:(_vm.university.director_name_arabe),callback:function ($$v) {_vm.$set(_vm.university, "director_name_arabe", $$v)},expression:"university.director_name_arabe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"اسم ولقب الكاتب العام","label-for":"اسم ولقب الكاتب العام"}},[_c('validation-provider',{attrs:{"name":"اسم ولقب الكاتب العام","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"اسم ولقب الكاتب العام"},model:{value:(_vm.university.general_secretary_name_arabe),callback:function ($$v) {_vm.$set(_vm.university, "general_secretary_name_arabe", $$v)},expression:"university.general_secretary_name_arabe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"العنوان","label-for":"العنوان"}},[_c('validation-provider',{attrs:{"name":"العنوان","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"العنوان"},model:{value:(_vm.university.address_arabe),callback:function ($$v) {_vm.$set(_vm.university, "address_arabe", $$v)},expression:"university.address_arabe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),(_vm.exist === false)?_c('div',{staticClass:"mt-2"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.addUniversity}},[_vm._v(" Ajouter ")])],1):_c('div',{staticClass:"mt-2"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":_vm.updateUniversity}},[_vm._v(" Modifier ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }