<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end" />
    <app-collapse>

      <app-collapse-item
        title="Données générales"
      >

        <!-- form -->

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Agréement"
                  label-for="Agréement"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Agréement"
                    rules="required"
                  >
                    <b-form-input
                      v-model="university.accreditation"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Agréement"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Préfixe"
                  label-for="Préfixe"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Préfixe"
                    rules="required"
                  >
                    <b-form-input
                      v-model="university.prefix"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Préfixe"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Nom du faculté"
                  label-for="Nom du faculté"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nom du faculté"
                    rules="required"
                  >
                    <b-form-input
                      v-model="university.university_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nom du faculté"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Acronyme"
                  label-for="Acronyme"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Acronyme"
                    rules="required"
                  >
                    <b-form-input
                      v-model="university.acronym"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Acronyme"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                  label="Nom de la société"
                  label-for="Nom de la société"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nom de la société"
                    rules="required"
                  >
                    <b-form-input
                      v-model="university.company_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nom de la société"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Matricule fiscal"
                  label-for="Matricule fiscal"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Matricule fiscal"
                    rules="required"
                  >
                    <b-form-input
                      v-model="university.tax_identification_number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Matricule fiscal"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Régistre du commerce"
                  label-for="Régistre du commerce"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Régistre du commerce"
                    rules="required"
                  >
                    <b-form-input
                      v-model="university.commercial_register"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Régistre du commerce"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                  label="Cnss"
                  label-for="Cnss"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cnss"
                    rules="required"
                  >
                    <b-form-input
                      v-model="university.cnss"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Cnss"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Rib"
                  label-for="Rib"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Rib"
                    rules="required"
                  >
                    <b-form-input
                      v-model="university.rib"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Rib"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Iban"
                  label-for="Iban"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Iban"
                    rules="required"
                  >
                    <b-form-input
                      v-model="university.iban"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Iban"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                  label="Code SWIFT"
                  label-for="Code SWIFT"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Code SWIFT"
                    rules="required"
                  >
                    <b-form-input
                      v-model="university.swift_code"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Code SWIFT"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="% TVA"
                  label-for="% TVA"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="% TVA"
                    rules="required"
                  >
                    <b-form-input
                      v-model="university.tva"
                      :state="errors.length > 0 ? false : null"
                      placeholder="% TVA"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <label for="example-datepicker">Date Agréement</label>
                <flat-pickr
                  v-model="university.accreditation_date"
                  class="form-control invoice-edit-input"
                />
              </b-col>

              <b-col md="4">
                <b-form-group
                  label="% RS Enseignant"
                  label-for="% RS Enseignant"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="% RS Enseignant"
                    rules="required"
                  >
                    <b-form-input
                      v-model="university.rs_teacher"
                      :state="errors.length > 0 ? false : null"
                      placeholder="% RS Enseignant"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="% RS Fournisseur"
                  label-for="% RS Fournisseur"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="% RS Fournisseur"
                    rules="required"
                  >
                    <b-form-input
                      v-model="university.rs_supplier"
                      :state="errors.length > 0 ? false : null"
                      placeholder="% RS Fournisseur"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="% RS Fonction libérale"
                  label-for="% RS Fonction libérale"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="% RS Fonction libérale"
                    rules="required"
                  >
                    <b-form-input
                      v-model="university.rs_liberal_function"
                      :state="errors.length > 0 ? false : null"
                      placeholder="% RS Fonction libérale"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

          </b-form>
        </validation-observer>
      </app-collapse-item>

      <app-collapse-item title="Cadres généraux">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Nom & prénom du PDG"
                label-for="Nom & prénom du PDG"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nom & prénom du PDG"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.fullname_ceo"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nom & prénom du PDG"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nom & Prénom Financier"
                label-for="Nom & Prénom Financier"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nom & Prénom Financier"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.fullname_financial"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nom & Prénom Financier"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Gender"
                label-for="gender"
              >
                <validation-provider
                  #default="{ errors }"
                  name="gender"
                  rules="required"
                >
                  <v-select

                    :clearable="false"
                    placeholder="Mademoiselle"
                    label="name"
                    :options="gender"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Secrétaire général(e)"
                label-for="Secrétaire général(e)"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Secrétaire général(e)"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.general_secretary_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Secrétaire général(e)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Gender"
                label-for="gender"
              >
                <validation-provider
                  #default="{ errors }"
                  name="gender"
                  rules="required"
                >
                  <v-select

                    :clearable="false"
                    placeholder="Mademoiselle"
                    label="name"
                    :options="gender"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Directeur"
                label-for="Directeur"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Directeur"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.director_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Directeur"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="CIN Directeur"
                label-for="CIN Directeur"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CIN Directeur"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.cin_director"
                    :state="errors.length > 0 ? false : null"
                    placeholder="CIN Directeur"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </app-collapse-item>

      <app-collapse-item title="Coordonnées">
        <b-form>
          <b-row>

            <b-col md="4">
              <b-form-group
                label="Adresse"
                label-for="Adresse"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Adresse"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.address"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Adresse"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Ville"
                label-for="Ville"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Ville"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.town"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ville"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Code postal"
                label-for="Code postal"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Code postal"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.postal_code"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Code postal"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Page Facebook"
                label-for="Page Facebook"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Page Facebook"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.facebook"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Page Facebook"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Page LinkedIn"
                label-for="Page LinkedIn"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Page LinkedIn"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.linkedin"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Page LinkedIn"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Email"
                label-for="Email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Tel"
                label-for="Tel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tel"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.phone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Tel"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Fax"
                label-for="Fax"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Fax"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.fax"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Fax"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Site Internet"
                label-for="Site Internet"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Site Internet"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.website"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Site Internet"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </app-collapse-item>

      <app-collapse-item title="Logo et Signatures">
        Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie
        brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly
        marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly
        beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake.
      </app-collapse-item>

      <app-collapse-item title="المعطيات بالعربية">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="اسم الجامعة"
                label-for="اسم الجامعة"
              >
                <validation-provider
                  #default="{ errors }"
                  name="اسم الجامعة"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.faculty_name_arabe"
                    :state="errors.length > 0 ? false : null"
                    placeholder="اسم الجامعة"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="اسم ولقب المدير"
                label-for="اسم ولقب المدير"
              >
                <validation-provider
                  #default="{ errors }"
                  name="اسم ولقب المدير"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.director_name_arabe"
                    :state="errors.length > 0 ? false : null"
                    placeholder="اسم ولقب المدير"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="اسم ولقب الكاتب العام"
                label-for="اسم ولقب الكاتب العام"
              >
                <validation-provider
                  #default="{ errors }"
                  name="اسم ولقب الكاتب العام"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.general_secretary_name_arabe"
                    :state="errors.length > 0 ? false : null"
                    placeholder="اسم ولقب الكاتب العام"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="العنوان"
                label-for="العنوان"
              >
                <validation-provider
                  #default="{ errors }"
                  name="العنوان"
                  rules="required"
                >
                  <b-form-input
                    v-model="university.address_arabe"
                    :state="errors.length > 0 ? false : null"
                    placeholder="العنوان"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

      </app-collapse-item>

    </app-collapse>

    <div
      v-if="exist === false"
      class="mt-2"
    >
      <b-button
        variant="primary"
        type="submit"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="addUniversity"
      >
        Ajouter
      </b-button>

    </div>
    <div
      v-else
      class="mt-2"
    >
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="updateUniversity"
      >
        Modifier
      </b-button>

    </div>

  </div>
</template>
<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import storeAuth from '@/store/store'
import flatPickr from 'vue-flatpickr-component'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,

} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    BButton,
    flatPickr,

  },
  data() {
    return {
      password: '',
      gender: ['Madame', 'Monsieur', 'Mademoiselle'],
      university: {
        accreditation: '',
        prefix: '',
        university_name: '',
        acronym: '',
        company_name: '',
        tax_identification_number: '',
        commercial_register: '',
        cnss: '',
        rib: '',
        iban: '',
        swift_code: '',
        tva: '',
        accreditation_date: '',
        rs_teacher: '',
        rs_supplier: '',
        rs_liberal_function: '',
        fullname_ceo: '',
        fullname_financial: '',
        general_secretary_name: '',
        director_name: '',
        cin_director: '',
        address: '',
        town: '',
        postal_code: '',
        facebook: '',
        linkedin: '',
        email: '',
        fax: '',
        phone: '',
        website: '',
        faculty_name_arabe: '',
        director_name_arabe: '',
        general_secretary_name_arabe: '',
        address_arabe: '',
        academic_year_id: '',
      },
      exist: false,
      required,

    }
  },
  created() {
    this.university.academic_year_id = storeAuth.state.currentAcademicYear.id
    this.getCurrentInfoUniversity()
  },

  methods: {
    async getCurrentInfoUniversity() {
      const { data } = await axios.get('/api/universities/', { params: { academic_year_id: storeAuth.state.currentAcademicYear.id } })
      if (data.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.university = data[0]
        this.exist = true
      }
      // console.log('exist', this.exist)
    },
    async addUniversity() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .post(
                '/api/universities/create/',
                {
                  accreditation: this.university.accreditation,
                  prefix: this.university.prefix,
                  university_name: this.university.university_name,
                  acronym: this.university.acronym,
                  company_name: this.university.company_name,
                  tax_identification_number: this.university.tax_identification_number,
                  commercial_register: this.university.commercial_register,
                  cnss: this.university.cnss,
                  rib: this.university.rib,
                  iban: this.university.iban,
                  swift_code: this.university.swift_code,
                  tva: this.university.tva,
                  accreditation_date: this.university.accreditation_date,
                  rs_teacher: this.university.rs_teacher,
                  rs_supplier: this.university.rs_supplier,
                  rs_liberal_function: this.university.rs_liberal_function,
                  fullname_ceo: this.university.fullname_ceo,
                  fullname_financial: this.university.fullname_financial,
                  general_secretary_name: this.university.general_secretary_namel,
                  director_name: this.university.director_name,
                  gender_director: this.university.gender_director,
                  gender_secretary: this.university.gender_secretary,
                  cin_director: this.university.cin_director,
                  address: this.university.address,
                  town: this.university.town,
                  postal_code: this.university.postal_code,
                  facebook: this.university.facebook,
                  linkedin: this.university.linkedin,
                  email: this.university.email,
                  academic_year: this.university.academic_year_id,
                  phone: this.university.phone,
                  fax: this.university.fax,
                  website: this.university.email,
                  faculty_name_arabe: this.university.faculty_name_arabe,
                  director_name_arabe: this.university.director_name_arabe,
                  general_secretary_name_arabe: this.university.general_secretary_name_arabe,
                  address_arabe: this.university.address_arabe,

                },
              )
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Information ajouté avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champs obligatoire')
          }, 1000)
        }
      })
    },

    async updateUniversity() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .put(
                `/api/universities/update/${this.university.id}/`,
                {
                  accreditation: this.university.accreditation,
                  prefix: this.university.prefix,
                  university_name: this.university.university_name,
                  acronym: this.university.acronym,
                  company_name: this.university.company_name,
                  tax_identification_number: this.university.tax_identification_number,
                  commercial_register: this.university.commercial_register,
                  cnss: this.university.cnss,
                  rib: this.university.rib,
                  iban: this.university.iban,
                  swift_code: this.university.swift_code,
                  tva: this.university.tva,
                  accreditation_date: this.university.accreditation_date,
                  rs_teacher: this.university.rs_teacher,
                  rs_supplier: this.university.rs_supplier,
                  rs_liberal_function: this.university.rs_liberal_function,
                  fullname_ceo: this.university.fullname_ceo,
                  fullname_financial: this.university.fullname_financial,
                  general_secretary_name: this.university.general_secretary_namel,
                  director_name: this.university.director_name,
                  gender_director: this.university.gender_director,
                  gender_secretary: this.university.gender_secretary,
                  cin_director: this.university.cin_director,
                  address: this.university.address,
                  town: this.university.town,
                  postal_code: this.university.postal_code,
                  facebook: this.university.facebook,
                  linkedin: this.university.linkedin,
                  email: this.university.email,
                  phone: this.university.phone,
                  fax: this.university.fax,
                  website: this.university.email,
                  faculty_name_arabe: this.university.faculty_name_arabe,
                  director_name_arabe: this.university.director_name_arabe,
                  general_secretary_name_arabe: this.university.general_secretary_name_arabe,
                  address_arabe: this.university.address_arabe,

                },
              )
            setTimeout(() => {
              this.showToast('success', 'top-center', 'information modifié avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champs obligatoire')
          }, 1000)
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
